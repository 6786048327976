<template>
  <div>
    <b-card>
      <b-card-title>Talep Görüntüle</b-card-title>
      <b-row>
        <b-col>
          <h5>Talep Eden</h5>
          <p>{{ purchasing_request.username }}</p>
        </b-col>
        <b-col>
          <h5>Marka</h5>
          <p>{{ purchasing_request.brand }}</p>
        </b-col>
        <b-col>
          <h5>Departman</h5>
          <p>{{ purchasing_request.department }}</p>
        </b-col>
        <b-col>
          <h5>Oluşturma Tarihi</h5>
          <p>{{ moment(purchasing_request.created).format('DD.MM.YYYY HH:mm') }}</p>
        </b-col>
        <b-col md="12">
          <h5>Notlar</h5>
          <p>{{ purchasing_request.notes }}</p>
        </b-col>
        <b-col
          md="12"
        >
          <h5>Talepler</h5>
          <request-lines />
        </b-col>
        <b-col
          v-if="purchasing_request.image"
          md="12"
        >
          <h5>Görsel</h5>
          <img
            :src="'media/purchasing/' + purchasing_request.image"
            alt=""
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
} from 'bootstrap-vue'
import requestLines from '@/views/Purchasing/components/Request_lines.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    requestLines,
  },
  data() {
    return {
      detailForm: false,
      submitStatus: false,
      formData: {
        title: null,
      },
    }
  },
  computed: {
    purchasing_request() {
      return this.$store.getters['purchasingRequests/getPurchasingRequest']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('purchasingRequests/purchasingRequestView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
  },
}
</script>

<style scoped>

</style>
